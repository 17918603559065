/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var BPC = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
                function checkSize() {
                    var screenSizeCheck = $(".service_list").css("display");
                    if (screenSizeCheck === "none") {
                        // Code for mobile-breakpoint only
                        var serviceSwiper = new Swiper('.services-swiper', {
                            effect: 'coverflow',
                            grabCursor: true,
                            centeredSlides: true,
                            slidesPerView: 'auto',
                            coverflow: {
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: false
                            }
                        });
                        var clientsSwiperMobile = new Swiper('.clients-swiper', {
                            spaceBetween: 0,
                            slidesPerView: 1,
                            nextButton: '.swiper-clients-next',
                            prevclients: '.swiper-button-prev',
                            speed: 2000,
                            autoplay: 500,
                            autoplayDisableOnInteraction: false,
                            loop: true
                        });
                        $('[data-teammate]').on({
                            click: function(e) {
                                e.preventDefault();
                                $(this).toggleClass('teammate_show');
                            }
                        });
                    } else {
                        var continuousElements = document.getElementsByClassName('animation');
                        for (var i = 0; i < continuousElements.length; i++) {
                            new Waypoint({
                                element: continuousElements[i],
                                handler: function() {
                                    $(this.element).addClass('animated').addClass('fadeInUp');
                                },
                                offset: '90%'
                            });
                        }
                        var clientsSwiper = new Swiper('.clients-swiper', {
                            spaceBetween: 0,
                            slidesPerView: 4,
                            speed: 2000,
                            freeMode: true,
                            autoplay: 500,
                            autoplayDisableOnInteraction: false,
                            loop: false
                        });
                        $('[data-teammate]').on({
                            mousedown: function(e) {
                                e.preventDefault();
                                var rowFirst = $(this);
                                if (!$(this).hasClass('row-first')) {
                                    rowFirst = $(this).prevAll('.row-first').first();
                                }
                                var rowPosition = $(rowFirst).position();
                                if ($('.teammate_info').hasClass('teammate_show')) {
                                    $('.teammate_info').removeClass('teammate_show');
                                }
                                $('.teammate_info').insertBefore(rowFirst);
                                $('.teammate_info').css({
                                    top: rowPosition.top
                                });
                            },
                            click: function(e) {
                                e.preventDefault();
                                var teammate = $('.teammate', this).clone();
                                $('.teammate_info .teammate_link').html(teammate);
                                $('.teammate_info').addClass('teammate_show');
                            }
                        });
                        $(document).on({
                            keyup: function(e) {
                                if (e.keyCode === 27 && $('.teammate_info').hasClass('teammate_show')) {
                                    $('.teammate_info').removeClass('teammate_show');
                                }
                            }
                        });
                        $('button.teammate_close').on({
                            click: function(e) {
                                $('.teammate_info').removeClass('teammate_show');
                            }
                        });
                    }
                }
                checkSize();
                $(window).resize(checkSize);

                var nav = responsiveNav(".nav-collapse", {
                    customToggle: "#toggleme",
                    openPos: "static",
                    closeOnNavClick: true,
                    open: function() {
                        $('header').addClass('nav-fixed');
                    },
                    close: function() {
                        $('header').removeClass('nav-fixed');
                    }
                });
                var swiper = new Swiper('.header-swiper', {
                    autoplay: 5000,
                    nextButton: '.header-swiper-button-next',
                    prevButton: '.header-swiper-button-prev',
                    spaceBetween: 0,
                    loop: true
                });
                var featuredSwiper = new Swiper('.featured-swiper', {
                    nextButton: '.featured-swiper-button-next',
                    prevButton: '.featured-swiper-button-prev',
                    spaceBetween: 0,
                    slidesPerView: 'auto',
                    paginationClickable: true,
                    loop: true
                });
                // instantiate the plugin
                var first_group = $('.filter_work > button:eq(0)').data('group');
                $('.filter_work > button:eq(0)').addClass('active');
                $('#work').shuffle({
                    itemSelector: '.work_item',
                    columnWidth: 389,
                    group: first_group
                });
                var $btns = $('.filter_work').children();
                $btns.on('click', function() {
                    var $this = $(this),
                        isActive = $this.hasClass('active'),
                        group = isActive ? 'all' : $this.data('group');
                    // Hide current label, show current label in title
                    if (!isActive) {
                        $('.filter_work .active').removeClass('active');
                    }
                    $this.toggleClass('active');
                    // Filter elements
                    $('#work').shuffle('shuffle', group);
                });
                $btns = null;
                var loadWork = function(work) {
                    $.post(bpc_modals.ajax_url, {
                            'action': 'load_work',
                            'work': work
                        },
                        /* Handle the response! */
                        function(response) {
                            $('.work_modal').html(response);
                        }).done(function() {
                        $('.video').fitVids();
                    });
                };
                $('[data-work]').on({
                    click: function() {
                        var work = $(this).data('work');
                        loadWork(work);
                    }
                });
                $('[data-work]').magnificPopup({
                    mainClass: 'snow_canvas',
                    items: {
                        src: '.work_modal',
                        type: 'inline',
                        alignTop: true,
                        closeBtnInside: true
                    },
                    removalDelay: 600
                });
                $('.video').fitVids();
                transformicons.add('.tcon');
                // Hide Header on on scroll down
                var didScroll;
                var lastScrollTop = 0;
                var delta = 5;
                var navHeight = $('header').outerHeight();
                $(window).scroll(function(event) {
                    didScroll = true;
                });

                function hasScrolled() {
                    var st = $(this).scrollTop();
                    // Make sure they scroll more than delta
                    if (Math.abs(lastScrollTop - st) <= delta) {
                        return;
                    }
                    // If they scrolled down and are past the navbar, add class .nav-up.
                    // This is necessary so you never see what is "behind" the navbar.
                    if (st > lastScrollTop && st > navHeight) {
                        // Scroll Down
                        $('header').removeClass('nav-down').addClass('nav-up');
                        transformicons.revert('.tcon-menu--xcross');
                    } else {
                        // Scroll Up
                        if (st + $(window).height() < $(document).height() && st > navHeight) {
                            $('header').addClass('nav-down');
                        } else {
                            $('header').removeClass('nav-down').removeClass('nav-up');
                        }
                    }
                    lastScrollTop = st;
                }

                setInterval(function() {
                    if (didScroll) {
                        hasScrolled();
                        didScroll = false;
                    }
                }, 250);
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };
    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = BPC;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';
            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
